
import Vue from "vue";

import Router from "vue-router";
Vue.use(Router);

const router = new Router({
   mode: "history",
   linkActiveClass: 'active',
   base: process.env.BASE_URL,
   scrollBehavior() {
      return { x: 0, y: 0 };
   },

   routes: [

      {
         path: "/",
         name: "landing-page",
         component: () => import("@/pages/Hotel.vue"),

      },
      {
         path: "/prop_list",
         component: () => import("@/pages/HotelListing.vue"),

      },
      {
         path: "/hotel_booking",
         component: () => import("@/pages/HotelBooking.vue"),

      },
      {
         path: "/payment_page",
         component: () => import("@/pages/Payment.vue"),

      },
      {
         path: "/payment_update",
         component: () => import("@/pages/PaymentUpdatePage.vue"),

      },
      {
         path: "/reservation_page",
         component: () => import("@/pages/HotelReservation.vue"),

      },


   ]
});



export default router
