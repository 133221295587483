<template>
  <div id="footer-scroll">
    <section class="property-owner">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-fff">Are you property owner?</h2>
            <p>You’ll know when an incredible offer appears</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center list-property">
            <a href="#"> List your property </a>
            <a href="#"> or get in touch </a>
          </div>
        </div>
      </div>
    </section>

    <section class="follow">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-444">Follow Gloveler</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <ul class="social-icons d-flex">
              <li>
                <a href="#"
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#"
                  ><i class="fa fa-google-plus" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-rss" aria-hidden="true"></i></a>
              </li>
            </ul>
            <p class="text-center">
              <a href="#" class="contact-us">Contact us</a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="main-footer">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-md-3 footer-logo col-lg-3">
            <a href="#">Gloveler</a>
            <p class="copyright">© COPYRIGHT ZUZZU 2016</p>
          </div>
          <div class="col footer-list">
            <h4>Company</h4>
            <ul>
              <li><a href="#">Press</a></li>
              <li><a href="#">Jobs</a></li>
              <li><a href="#">About us</a></li>
            </ul>
          </div>
          <div class="col footer-list">
            <h4>Legal</h4>
            <ul>
              <li><a href="#">Terms of use</a></li>
              <li><a href="#">Privacy policy</a></li>
              <li><a href="#">Legal notice</a></li>
            </ul>
          </div>
          <div class="col footer-list">
            <h4>Information</h4>
            <ul>
              <li><a href="#">Partner</a></li>
              <li><a href="#">Contact</a></li>
              <li><a href="#">Premium</a></li>
            </ul>
          </div>
          <div class="col-md-3 footer-list">
            <h4>Gloveler</h4>
            <ul>
              <li><a href="#">Bed & Breakfasts in Germany</a></li>
              <li><a href="#">Gloveler Blog</a></li>
              <li><a href="#">List your accommodation for free</a></li>
            </ul>
          </div>
        </div>
        <div class="row mob-block">
          <div class="col-12 text-center">
            <p class="copyright">© COPYRIGHT ZUZZU 2016</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>